import { displayUserImage } from "../../validation/image-clean";

import isEmpty from "../../validation/is-empty";
import { translate } from "../../utils/translation";
import { ImageDisplay } from "../images/ImageDisplay";

export function FileTray(props) {
  const { file } = props;
  let fileName = "Keep Existing";

  if (file !== null) {
    fileName = file.name;
  }

  return (
    <div className="company-create-file-display">
      <b>{translate("Logo")}:</b> {fileName}
    </div>
  );
}

export function DisplayNameUser(props) {
  const { user } = props;

  if (isEmpty(user)) {
    return null;
  }

  return (
    <div className="pciu-row">
      <div className="pciu-name"></div>

      <ImageDisplay
        image={displayUserImage(user)}
        imageWidth={"30px"}
        imageHeight={"30px"}
        borderRadius={"15px"}
        isBezierDisabled={true}
      />

      <div className="pciu-name">{user.name}</div>
      <div className="pciu-name">{user.surname}</div>
    </div>
  );
}

export function Upload(props) {
  const { data } = props;
  const { uploadFile } = props;
  return (
    <div
      id="comapny-upload-button"
      className="general-upload-button"
      onClick={async () => {
        uploadFile(data);
      }}
    >
      {translate("Save")}
    </div>
  );
}

export function DisplayError(props) {
  const { error } = props;
  return (
    <div className="company-create-error-container">
      <div className={error.type}>
        <b>{error.text}</b>
      </div>
    </div>
  );
}

export function onConsent(event, set) {
  const value = event.target.checked;
  set(value);
}
