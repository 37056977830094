import { authHeader } from "../../_helpers/auth-header";
import { sharedAPIs } from "../../api/api-endpoints/SharedServiceEndpoints";
import { getDmsToken } from "../../active-directory/getDmsToken";
import isEmpty from "../../validation/is-empty";

export async function uploadFile(data) {
  if (
    data.field.name === "" ||
    data.field.email_domain === "" ||
    data.field.address === "" ||
    data.field.region === "" ||
    data.field.account_lead === ""
  ) {
    return data.setError({
      text: "Please complete fields",
      type: "feedback-error",
    });
  }

  data.setError({
    text: "Saving...",
    type: "feedback-success",
  });

  const formData = new FormData();
  formData.append("file", data.file);
  formData.append("field", JSON.stringify(data.field));
  const url = sharedAPIs().edit_company;

  // DMS
  const dmsToken = await getDmsToken();
  let dmsBearer = "";
  if (!isEmpty(dmsToken)) {
    dmsBearer = dmsToken.accessToken;
  }

  const config = {
    method: "POST",
    body: formData,
    headers: {
      ...authHeader({ authJson: false, authForm: true, guestJson: false }),
      dmsBearer: `Bearer ${dmsBearer}`,
    },
  };

  let response = [];
  try {
    response = await fetch(url, config);
  } catch (e) {
    return response;
  }

  if (response.ok) {
    // Response OK
  } else {
    return data.setError({
      text: "Connection Error",
      type: "feedback-error",
    });
  }

  data.setError({
    text: "Saved Successfully",
    type: "feedback-success",
  });

  data.companies.set(await data.companies.get());
  data.setShow(false);
  if (data.setParentModalShow) {
    data.setParentModalShow(false);
  }
}
